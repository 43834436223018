import { useTranslation } from 'react-i18next';
import { yearsActive } from '../data';

export const About = ({css}) => {
    const { t } = useTranslation();

    return (
        <section className={css('section', 'about')} id={'about'}>
            <div className={css('section__title', 'section__title--no-subtitle')}>
                <h2>{t('section.about.title')}</h2>
            </div>
            <div className={css('section__content')}>
                <div>
                    <p>{t('section.about.content.0.p', { years: yearsActive})}</p>
                </div>
                <div>
                    <h3>{t('section.about.content.1.h4')}</h3>
                    <p>{t('section.about.content.1.p', { years: yearsActive})}</p>
                </div>
                <div>
                    <h3>{t('section.about.content.2.h4')}</h3>
                    <p>{t('section.about.content.2.p')}</p>
                </div>
                <div>
                    <h3>{t('section.about.content.3.h4')}</h3>
                    <p>{t('section.about.content.3.p')}</p>
                </div>
                <div>
                    <h3>{t('section.about.content.4.h4')}</h3>
                    <p>{t('section.about.content.4.p')}</p>
                </div>
            </div>
        </section>
    )
}
