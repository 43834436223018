import React from 'react';
import css from './Loading.module.css';

export const Loading = () => (
    <div className={css.cover}>
        <div className={css.centered}>
            <div className={css.ellipsis}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
);