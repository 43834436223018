import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import { Svg } from '../../components';
import { counts } from '../data';

export const Counts = ({css, url, orientation}) => {
    const { t} = useTranslation();

    return (
        <section className={css('section', 'section--no-title', 'counts')} id={'counts'}>
            <div className={css('section__content')}>
                <figure itemProp={'image'}>
                    <img src={`${url}assets/img/counts-img.svg`} alt={''} />
                </figure>
                <ul className={css('counts-list')}>
                    {counts.map((count, index) => (
                        <li key={index}>
                            <Svg icon={count.icon} size={30} />
                            <CountUp
                                end={count.number} 
                                duration={count.duration/1000}
                                separator={'.'}
                                delay={3}
                            />
                            <p>{t(count.text)}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}