import { useTranslation } from 'react-i18next';
import { year } from './data';
import { Svg } from '../components';

export const Footer = ({css}) => {
    const {t} = useTranslation();

    return (
        <footer className={css('footer')} id={'footer'}>
            <div className={css('footer__copyright')}>
                <span>
                    &copy;&nbsp;{year} <strong itemProp="legalName">
                    S.C.&nbsp;Capmanlux&nbsp;S.R.L.</strong></span> <span>
                    {t('footer.copyright.all_rights_reserved')}</span>
            </div>
            <div className={css('footer__links')}>
                <span>
                    <Svg className={css('pw-icon')} icon={'pw'} size={24} />
                </span>
            </div>
        </footer>
    );
}
