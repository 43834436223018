import { About } from './About';
import { Clients } from './Clients';
import { Contact } from './Contact';
import { Counts } from './Counts';
import { Equipment } from './Equipment';
import { Hero } from './Hero';
import { Portfolio } from './Portfolio';
import { Services } from './Services';
import { Test } from './Test';

export const Section = ({children}) => <>{children}</>;
Section.About = About;
Section.Clients = Clients;
Section.Contact = Contact;
Section.Counts = Counts;
Section.Equipment = Equipment;
Section.Hero = Hero;
Section.Portfolio = Portfolio;
Section.Services = Services;
Section.Test = Test;

const kids = { About, Clients, Contact, Counts, Equipment, Hero, Portfolio, Services }
export const Sect = () => <>{kids}</>;
