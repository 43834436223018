import { Fragment, forwardRef } from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { links } from './data';

export const MobileNav = forwardRef(({css, mobileNav, contactForm}, ref) => {
    const { t, i18n } = useTranslation();
    const changeLanguage = e => i18n.changeLanguage(e.target.value);
    
    return(
    <nav className={css('mobile-nav', {'mobile-nav--active': mobileNav.state})} id={'mobile-nav'}>
        <div className={css('mobile-nav__header')}>
            <fieldset className={css('switcher')}>
                {['ro', 'ru'].map((language, index) => (
                    <Fragment key={index}>
                        <input 
                            className={css('switcher__input')}
                            type={'radio'} 
                            name={'language'}
                            id={t(language)}
                            value={language}
                            autoComplete={'off'}
                            checked={language === i18n.language}
                            onChange={changeLanguage}
                        />
                        <label className={css('switcher__label')} htmlFor={t(language)}>{t(language)}</label>
                    </Fragment>
                ))}
                <span className={css('switcher__zipper')}></span>
            </fieldset>
        </div>
        <ul className={css('mobile-nav__list')} role={'menu'}>
            {links.map((link, index) => (
                <li className={css('mobile-nav__item')} key={index}>
                    <Link 
                        className={css('mobile-nav__link', {'mobile-nav__link--offer': link.route === 'contact-form'})}
                        activeClass={css('mobile-nav__link--active')}
                        to={link.route}
                        containerId={'main'}
                        smooth
                        offset={-30}
                        onClick={link.route === 'contact-form' 
                            ? contactForm.focus
                            : mobileNav.close}
                    >
                        {t(link.label)}
                    </Link>
                </li>
            ))}
        </ul>
    </nav>
);
})