import React, { Suspense, createRef, useState, useEffect, useMemo } from 'react';
import { deviceDetect } from 'react-device-detect';
import Recaptcha from 'react-recaptcha';
import { useWindowSize } from './hooks';
import classNames from 'classnames/bind';
import styles from './styles/styles.module.scss';
import {
  Loading,
  Header,
  Main,
  Section,
  Footer,
  MobileNav
} from './components';

const App = () => {
  const device = deviceDetect();
  const windowSize = useWindowSize();

  const orientation = useMemo(() => {
    return {
      landscape: windowSize.width >= (windowSize.height * 4/3),
      portrait: windowSize.height >= (windowSize.width * 4/3)
    }
  }, [windowSize]);

  const css = classNames.bind(styles);
  const domain = 'pavator.md';
  const url = `https://www.${domain}/`;
  const contactRef = createRef(null),
        formRef = createRef(null),
        inputRef = createRef(null),
        headerRef = createRef(null),
        mainRef = createRef(null),
        mobileNavRef = createRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const [scrollY, setScrollY] = useState(0);
  useEffect(() => {
    const node = mainRef.current;
    const handleScrollY = () => setScrollY(node.scrollTop);
    node && node.addEventListener('scroll', handleScrollY);
    return () => node && node.removeEventListener('scroll', handleScrollY);
  }, [mainRef, scrollY]);


  const mobileNav = useMemo(() => {
    return {
      state: isOpen,
      set: state => setIsOpen(state),
      open: () => mobileNav.set(true),
      close: () => mobileNav.set(false),
      toggle: () => mobileNav.set(state => !state),
      outside: e => {
        if (!mobileNav.state) return
        if (mobileNavRef.current 
        && !mobileNavRef.current.contains(e.target)) mobileNav.close();
        }
    }
  }, [isOpen, mobileNavRef, setIsOpen]);
  
  const contactForm = {
    focus: () => {
      mobileNav.close();
      inputRef.current.focus();
    },
    reset: () => formRef.current.reset()
  }
  
  const props = {
    device,
    orientation,
    css,
    domain,
    url,
    scrollY,
    mobileNav,
    contactForm
  }

  useEffect(() => device.isMobile 
               && orientation.landscape 
               && mobileNav.state 
               && mobileNav.close());

  return (
    <Suspense fallback={<Loading />}>
      <article className={css('app')}  itemScope itemType="https://schema.org/LocalBusiness">
        <Header {...props} ref={headerRef} />
        <Main {...props} ref={mainRef}>
          <Section.Hero {...props} />
          <Section.Clients {...props} />
          <Section.About {...props} />
          <Section.Counts {...props} />
          <Section.Services {...props} />
          <Section.Equipment {...props} />
          <Section.Portfolio {...props} />
          <Section.Contact {...props} ref={{contactRef, formRef, inputRef}} />
          <Footer {...props} />
        </Main>
        {device.isMobile && <MobileNav {...props} ref={mobileNavRef} />}
        <Recaptcha sitekey={'6LevMqcaAAAAAO9FRF9P_bwZHHuWnLdDR8hDeuJG'} />
      </article>
    </Suspense>
  );
}

export default App;
