import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { photos } from '../data';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export const Portfolio = ({css}) => {
    const {t} = useTranslation();
    const [lightbox, setLightbox] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const prevIndex = (photoIndex + photos.length - 1) % photos.length;
    const nextIndex = (photoIndex + 1) % photos.length;

    return (
        <section className={css('section', 'portfolio')} id={'portfolio'}>
            <div className={css('section__title', 'section__title--no-subtitle')}>
                <h2>{t('section.portfolio.title')}</h2>
            </div>
            <div className={css('section__content')}>
                <Gallery photos={photos} onClick={(_, {index}) => {
                    setPhotoIndex(index);
                    setLightbox(true);
                }} />
                {lightbox && <Lightbox
                    mainSrc={photos[photoIndex].srchq}
                    prevSrc={photos[prevIndex].srchq}
                    nextSrc={photos[nextIndex].srchq}
                    onCloseRequest={() => setLightbox(false)}
                    onMovePrevRequest={() => setPhotoIndex(prevIndex)}
                    onMoveNextRequest={() => setPhotoIndex(nextIndex)}
                />}
            </div>
        </section>
    );
}

