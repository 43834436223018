import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';

export const Hero = ({device, css, url}) => {
    const { t } = useTranslation();
    
    return (
        <section className={css('section', 'section--full', 'hero')} id={'hero'}>
            <div className={css('section__title')}>
                <h1 itemProp={'slogan'}>{t('section.hero.title')}</h1>
                <h2 itemProp={'description'}>{t('section.hero.subtitle')}</h2>
                <Link 
                    className={css(
                        'button', 
                        'button__outline', 
                        'button__outline--blue', {
                            'button--full': device.isMobile
                        })} 
                    to={'about'}
                    containerId={'main'}
                    offset={-30}
                    smooth
                >
                    {t('section.hero.button_label')}
                </Link>
            </div>
            <div className={css('section__content')}>
                <figure>
                    <img 
                        src={`${url}assets/img/hero-img.png`} 
                        alt={t('section.hero.img_alt')} 
                        itemProp={'image'}
                    />
                </figure>
            </div>
        </section>
    );
}