import { useTranslation } from 'react-i18next';
import { services } from '../data';

export const Services = ({ css }) => {
    const { t } = useTranslation();

    return (
        <section className={css('section', 'section--full', 'services')} id={'services'}>
            <div className={css('section__title')}>
                <h2>{t('section.services.title')}</h2>
                <p>{t('section.services.subtitle')}</p>
            </div>
            <div className={css('section__content')}>
                <ul className={css('section__content--horizontal-list', 'section__cardlist')}>
                    {services.map((card, i) => (
                        <li className={css('card')} key={i}>
                            <figure><img src={card?.image} alt={t(card?.title)} /></figure>
                            <h2>{t(card?.title)}</h2>
                            <p>{t(card?.description)}</p>
                            <footer className={css('cols-2')}>
                                <div>
                                    <span>{t('section.services.words.just_work')}</span>
                                    <span>{t('section.services.words.from')} <strong>
                                        {card.price.justWork}</strong> {t('section.services.words.currency')}
                                        /{t('section.services.words.m2')}{card.mu === 'm2' && <sup>2</sup>}
                                    </span>
                                </div>
                                <div>
                                    <span>{t('section.services.words.work_and_materials')}</span>
                                    <span>{t('section.services.words.from')} <strong>
                                        {card.price.workAndMaterials}</strong> {t('section.services.words.currency')}
                                        /{t('section.services.words.m2')}{card.mu === 'm2' && <sup>2</sup>}
                                    </span>
                                </div>
                            </footer>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}
