import { useTranslation } from 'react-i18next';
import { equipment } from '../data';



export const Equipment = ({css}) => {
    const { t } = useTranslation(); 
    return (
        <section className={css('section', 'section--full', 'equipment')} id={'equipment'}>
            <div className={css('section__title')}>
                <h2>{t('section.equipment.title')}</h2>
                <p>{t('section.equipment.subtitle')}</p>
            </div>
            <div className={css('section__content')}>
                <ul className={css('section__content--horizontal-list', 'section__cardslist')}>
                    {equipment.map((card, i) => (
                        <li className={css('card')} key={i}>
                            <figure><img src={card?.image} alt={t(card?.title)} /></figure>
                            <h2>{t(card?.title)}</h2>
                            <p>{t(card?.description)}</p>
                            <footer>
                                <div>
                                    {card?.price?.startingAt && <span>{t('section.equipment.words.from')}</span>}
                                    <strong>&nbsp;{card?.price?.startingAt || card?.price?.fixed}&nbsp;</strong>
                                    <span>{t('section.equipment.words.currency')}/{t('section.equipment.words.hour')}</span>
                                </div>
                            </footer>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}