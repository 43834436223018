import React from 'react';
import sprite from './icons.svg';

export const Svg = props => {
    const icon = props.icon;
    const color = props.color;
    const className = props.className;
    const width = props.size ? props.size : props.width;
    const height = props.size ? props.size : props.height;
    return (
        <svg 
            width={width} 
            height={height} 
            fill={color} 
            className={className}
        >
            <use href={`${sprite}#${icon}`} />
        </svg>
    );
}