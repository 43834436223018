import React from 'react';
import { render, hydrate } from 'react-dom';
import './i18n';
import './styles/global.css';
import App from './App';

const pavator = document.getElementById('pavator');

pavator.hasChildNodes() 
  ? hydrate(<App />, pavator) 
  :  render(<App />, pavator);