import * as dayjs from 'dayjs';

export const yearsActive = dayjs().diff('2006-12-14', 'years');

export const links = [
  { route: 'about', label: 'header.nav.about' },
  { route: 'services', label: 'header.nav.services' },
  { route: 'equipment', label: 'header.nav.equipment' },
  { route: 'portfolio', label: 'header.nav.portfolio' },
  { route: 'contact', label: 'header.nav.contact' },
  { route: 'contact-form', label: 'header.nav.get_offer', isButton: true }
];

export const clients = [
  'basconslux',
  'dansicons',
  'nr1',
  'olmosdon',
  'bemol',
  'lukoil',
  'tirex',
  'us-embassy',
  'drochia',
  'ocnita'
];

export const counts = [
  { 
    icon: 'time', 
    number: yearsActive, 
    duration: 2000,
    text: 'section.counts.experience' 
  },
  { 
    icon: 'worker', 
    number: 30, 
    duration: 2500,
    text: 'section.counts.employees' 
  },
  { 
    icon: 'construction', 
    number: 50000, 
    duration: 2550,
    text: 'section.counts.works' 
  }
];

export const services = [
  {
      image: '/assets/img/services/1.png',
      title: 'section.services.card1.title',
      price: {
          justWork: 80,
          workAndMaterials: 300
      },
      mu: 'm2'
  },
  {
      image: '/assets/img/services/2.png',
      title: 'section.services.card2.title',
      price: {
          justWork: 100,
          workAndMaterials: 400
      },
      mu: 'm2'
  },
  {
      image: '/assets/img/services/3.png',
      title: 'section.services.card3.title',
      price: {
          justWork: 120,
          workAndMaterials: 500
      },
      mu: 'm2'
  },
  {
      image: '/assets/img/services/4.png',
      title: 'section.services.card4.title',
      price: {
          justWork: 40,
          workAndMaterials: 200
      },
      mu: 'm'
  },
  {
      image: '/assets/img/services/4.png',
      title: 'section.services.card5.title',
      price: {
          justWork: 60,
          workAndMaterials: 300
      },
      mu: 'm'
  },
];

export const equipment = [
  {
      image: '/assets/img/equipment/bobcat.png',
      title: 'section.equipment.card1.title',
      price: {
          startingAt: 300
      },
      mu: 'm2'
  },
  {
      image: '/assets/img/equipment/renault.png',
      title: 'section.equipment.card2.title',
      price: {
          startingAt: 300
      },
      mu: 'm2'
  },
  {
      image: '/assets/img/equipment/hamm.png',
      title: 'section.equipment.card3.title',
      price: {
          startingAt: 350
      },
      mu: 'm2'
  },
  {
      image: '/assets/img/equipment/manipulator.png',
      title: 'section.equipment.card4.title',
      price: {
          fixed: 700
      },
      mu: 'm'
  },
];

export const photos = [
  {
    src: "https://www.pavator.md/assets/img/portfolio/portfolio-1.jpg",
    srchq: "https://www.pavator.md/assets/img/portfolio/portfolio-1-hq.jpg",
    alt: "",
    width: 4,
    height: 3
  },
  {
    src: "https://www.pavator.md/assets/img/portfolio/portfolio-2.jpg",
    srchq: "https://www.pavator.md/assets/img/portfolio/portfolio-2-hq.jpg",
    alt: "",
    width: 4,
    height: 3
  },
  {
    src: "https://www.pavator.md/assets/img/portfolio/portfolio-3.jpg",
    srchq: "https://www.pavator.md/assets/img/portfolio/portfolio-3-hq.jpg",
    alt: "",
    width: 4,
    height: 3
  },
  {
    src: "https://www.pavator.md/assets/img/portfolio/portfolio-4.jpg",
    srchq: "https://www.pavator.md/assets/img/portfolio/portfolio-4-hq.jpg",
    alt: "",
    width: 4,
    height: 3
  },
  {
    src: "https://www.pavator.md/assets/img/portfolio/portfolio-5.jpg",
    srchq: "https://www.pavator.md/assets/img/portfolio/portfolio-5-hq.jpg",
    alt: "",
    width: 4,
    height: 3
  },
  {
    src: "https://www.pavator.md/assets/img/portfolio/portfolio-6.jpg",
    srchq: "https://www.pavator.md/assets/img/portfolio/portfolio-6-hq.jpg",
    alt: "",
    width: 4,
    height: 3
  }
];

export const year = dayjs().get('year');