import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-scroll';
import { Svg } from '../components';
import { links } from './data';

export const Header = forwardRef(({ 
    device, 
    orientation,
    css,
    scrollY,
    contactForm, 
    mobileNav
}, ref) => {
    
    const { t, i18n } = useTranslation();
    const handleLanguageChange = e => i18n.changeLanguage(e.target.lang);
    const notCurrentLang = ['ro', 'ru'].filter(e => e !== i18n.language)[0];

    const LogoLink = ({children}) => 
    scrollY > 0 
        ? <Link 
            className={css('header-logo')}
            to={'main'} 
            containerId={'main'} 
            smooth={true}>{children}</Link>
        : <a className={css('header-logo')} href={'/'} tabIndex={-1}>{children}</a> 
    
    return (
        <header className={css('header', {'header--scrolled': scrollY > 20})} ref={ref}>
            <LogoLink>
                <figure itemProp="logo"/>
            </LogoLink>
            {device.isMobile && orientation.portrait
            ?
                <figure 
                    className={css('hamburger', {'hamburger--open': mobileNav.state})} 
                    onClick={mobileNav.toggle}
                >
                    <div />
                    <div />
                </figure>
            :
                <nav className={css('header-nav', {open: mobileNav.state})}>
                    <ul className={css('header-nav__list')}>
                        {links.map((link, index) => {
                            return (
                            <li className={css('header-nav__item')} key={index}>
                                <Link
                                    className={css('header-nav__link', {btn: link.isButton})}
                                    activeClass={css('header-nav__link--active')}
                                    to={link.route}
                                    containerId={'main'}
                                    offset={-30}
                                    smooth={true}
                                    tabIndex={index+1}
                                    onClick={link.route === 'contact-form' 
                                        ? contactForm.focus
                                        : mobileNav.close}
                                >
                                    {t(link.label)}
                                </Link>
                            </li>
                        )})}
                        <li className={css('header-nav__item')}>
                            <div 
                                className={css('header-nav__link')}
                                onClick={handleLanguageChange}
                                lang={notCurrentLang}
                            >
                                <Svg icon={`flag-${notCurrentLang}`} size={16} />
                            </div>
                        </li>
                    </ul>
                </nav>
            }
        </header>
    );
});