import { 
    useState, 
    useEffect, 
    useMemo,
    useRef, 
    forwardRef, 
} from 'react';
import { 
    YMaps, 
    Map, 
    FullscreenControl, 
    ZoomControl, 
    Placemark 
} from 'react-yandex-maps';
import { useForm, ValidationError } from '@formspree/react';
import { useTranslation } from 'react-i18next';
import { Svg } from '../Svg';

export const Contact = forwardRef(({device, css, contactForm}, {contactRef, formRef, inputRef}) => {
    const { t, i18n } = useTranslation();

    // const mapLinkPrefix = device.isMobile 
    //                     ? 'yandexmaps://maps.yandex.ru/?oid=' 
    //                     : 'https://yandex.ru/maps/org/';
    const mapLink = 'https://yandex.ru/maps/org/236440902351';

    const [state, handleSubmit] = useForm("xjvjvdva");

    const submitButton = useRef();

    const [zoom, setZoom] = useState(11);
    const mapState = useMemo(() => ({ 
        type: 'yandex#map',
        center: [47.024506, 28.874755], 
        zoom: zoom,
        controls: []
    }), [zoom]);
    const handleZoom = () => setZoom(zoom => (zoom === 11 ? 16 : 11));

    useEffect(() => {
        const node = submitButton.current;
        const classList = [
            {'error-shake': !state.succeeded},
            {'button__fill--red': !state.succeeded},
            {'button__fill--blue': state.succeeded}
        ];
        
        const handleSuccessButton = () => {
            classList.forEach(e => css(e) && node.classList.add(css(e)));
            setTimeout(() => classList.forEach(e => css(e) && node.classList.remove(css(e))), 3000);
        }
        node.addEventListener('click', handleSuccessButton);
        return () => {
            node.removeEventListener('click', handleSuccessButton);
            state.succeeded && contactForm.reset();
        }
    }, [contactForm, css, state]);

    return (
    <section className={css('section', 'contact')} id={'contact'} ref={contactRef}>
        <div className={css('section__title', 'section__title--no-subtitle')}>
            <h2>{t('section.contact.title')}</h2>
        </div>
        <div className={css('section__content')} itemScope itemType={'https://schema.org/LocalBusiness'}>
            <div className={css('contact__info')}>
                <h2 itemProp={'brand'}>Pavator</h2>
                <p>{t('section.contact.description')}</p>
            </div>
            <address className={css('contact__address')} itemProp={'address'}>
                <div className={css('contact__address--address')} itemScope itemType={'http://schema.org/PostalAddress'}>
                    <Svg icon={'location'} size={30} />
                    <a href={mapLink} target={'_blank'} rel={'noreferrer'}>
                        <span itemProp={'streetAddress'}>{t('section.contact.address.street')}</span>
                        <br />
                        <span itemProp={'addressLocality'}>
                        {t('section.contact.address.city')}</span>, <span itemProp={'addressCountry'}>
                        {t('section.contact.address.country')}</span> 🇲🇩
                    </a>
                </div>
                <div className={css('contact__address--email')}>
                    <Svg icon={'envelope'} size={30} />
                    <a href="mailto:office@pavator.md" itemProp={'email'}>
                        office@pavator.md
                    </a>
                </div>
                <div className={css('contact__address--tel')}>
                    <Svg icon={'phone'} size={30} />
                    <div className={css('phones-list')}>
                        <a href="tel:+37369127118" itemProp={'telephone'}>
                            +373 (69) 127118
                        </a>
                        <a href="tel:+37369124620" itemProp={'telephone'}>
                            +373 (69) 124620
                        </a>
                        <a href="tel:+37360839394" itemProp={'telephone'}>
                            +373 (60) 839394
                        </a>
                    </div>
                </div>
                <div className={css('contact__social-media-icons')}>
                    <a href={"https://www.facebook.com/capmanlux"} target={'_blank'} rel={'noreferrer'}>
                        <Svg icon={'facebook'} size={32} />
                    </a>
                    <a href={"https://www.instagram.com/capmanlux"} target={'_blank'} rel={'noreferrer'}>
                        <Svg icon={'instagram'} size={32} />
                    </a>
                </div>
            </address>
            <YMaps query={{lang: `${i18n.language}_RU`}}>
                <Map 
                    className={css('contact__map')}
                    state={mapState}
                    itemScope
                    itemType={'https://schema.org/Map'}
                >
                    <FullscreenControl />
                    <ZoomControl />
                    <Placemark 
                        defaultGeometry={[47.024506, 28.874755]} 
                        properties={{iconCaption: 'Pavator'}}
                        options={{preset: 'islands#bluePocketIcon'}}
                        onClick={handleZoom}
                    />
                </Map>
            </YMaps>
            <form 
                className={css('contact__form')} 
                id={'contact-form'}
                onSubmit={handleSubmit} 
                ref={formRef}
            >
                <fieldset>
                    <input 
                        type={'text'} 
                        name={'name'} 
                        placeholder={t('section.contact.form.name')}
                        autoComplete={'name'} 
                        inputMode={'text'} 
                        required
                        ref={inputRef}
                    />
                    <ValidationError 
                        className={css('contact__form-error')} 
                        field="name" 
                        prefix="Name" 
                        errors={state.errors} 
                    />
                </fieldset>
                <fieldset>
                    <input 
                        type={'email'} 
                        name={'email'} 
                        placeholder={t('section.contact.form.email')}
                        autoComplete={'email'} 
                        inputMode={'email'} 
                        required
                    />
                    <ValidationError 
                        className={css('contact__form-error')} 
                        field="email" 
                        prefix="email" 
                        errors={state.errors} 
                    />
                </fieldset>
                <fieldset>
                    <input 
                        type={'tel'} 
                        name={'tel'} 
                        placeholder={t('section.contact.form.tel')}
                        autoComplete={'tel'} 
                        inputMode={'tel'} 
                        required
                    />
                    <ValidationError 
                        className={css('contact__form-error')} 
                        field="tel" 
                        prefix="Tel" 
                        errors={state.errors} 
                    />
                </fieldset>
                <fieldset>
                    <textarea 
                        id={'message'} 
                        name={'message'} 
                        placeholder={t('section.contact.form.message')}
                        inputMode={'text'} 
                        rows={5}
                        wrap={'soft'}
                        required 
                    />
                    <ValidationError 
                        className={css('contact__form-error')} 
                        field="message" 
                        prefix="Message" 
                        errors={state.errors} 
                    />
                </fieldset>
                <button 
                    className={css(
                        'button', 
                        'button__fill', {
                            'button__transition--slow': state.succeeded,
                            'button--full': device.isMobile
                        })} 
                    type={'submit'} 
                    disabled={state.submitting}
                    ref={submitButton}
                >
                    {t('section.contact.form.submit')}
                </button>
                <input type="hidden" name="_language" value={i18n.language} />
            </form>
        </div>
    </section>
    );
});


