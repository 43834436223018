import { useTranslation } from 'react-i18next';
import { clients } from '../data';

export const Clients = ({css, url}) => {
    const { t } = useTranslation();

    return (
        <section className={css('section', 'section--full', 'section--no-title', 'clients')} id={'clients'}>
            <div className={css('section__content', 'section__content--spacing')}>
                <ul className={css('clients__list')}>
                    {clients.map(client => (
                        <li key={client}>
                            <img
                                srcSet={`${url}assets/img/clients/${client}@3x.png 3x, ${url}assets/img/clients/${client}@2x.png 2x, ${url}assets/img/clients/${client}.png 1x`}
                                src={`${url}assets/img/clients/${client}.png`}
                                alt={t(`section.clients.${client}`)}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
}
